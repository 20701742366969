"use client";

import Link from "next/link";
import { signIn } from "next-auth/react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/navigation";
import { zodResolver } from "@hookform/resolvers/zod";

import { errorIntoString } from "@/lib/utils";
import { OAUTH_APPLE_ENDPOINT, OAUTH_GOOGLE_ENDPOINT } from "@/lib/constant";
import { emailSignInSchema, EmailSignInSchema } from "@/lib/schemas/email-signin";

import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import TextInput from "@/components/form/TextInput";
import { useToast } from "@/components/ui/use-toast";
import PasswordInput from "@/components/form/PasswordInput";
import AuthProviderButton from "@/components/AuthProviderButton";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";

import appleIcon from "/public/assets/login/login-form/apple.png";
import googleIcon from "/public/assets/login/login-form/google.png";

export default function LoginForm() {
	const router = useRouter();
	const { toast } = useToast();
	const form = useForm<EmailSignInSchema>({
		resolver: zodResolver(emailSignInSchema),
		defaultValues: {
			email: "",
			keepMeSignedIn: false,
		},
	});

	const onSubmit = async (values: EmailSignInSchema) => {
		const result = await signIn("credentials", {
			redirect: false,
			email: values.email,
			password: values.password,
		});

		if (!result?.ok) {
			const ex = errorIntoString(result?.error ?? "Unknown Error");

			return toast({
				variant: "destructive",
				title: "Failed to initiate sign-in",
				description: ex,
			});
		}

		router.push("/");
		router.refresh();
	};

	const handleGoogleAuth = () => {
		const searchParams = new URLSearchParams({
			client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!,
			redirect_uri: `${process.env.NEXT_PUBLIC_FRONTEND_URI}/login/google`,
			response_type: "code",
			scope: "email profile",
			include_granted_scopes: "true",
		});

		window.location.href = `${OAUTH_GOOGLE_ENDPOINT}?${searchParams}`;
	};

	const handleAppleAuth = () => {
		const searchParams = new URLSearchParams({
			client_id: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID!,
			redirect_uri: `${process.env.NEXT_PUBLIC_FRONTEND_URI}/login/apple`,
			response_type: "code",
			scope: "name email",
			response_mode: "form_post",
		});
		window.location.href = `${OAUTH_APPLE_ENDPOINT}?${searchParams}`;
	};

	return (
		<Card className="w-full max-w-md rounded-3xl border-none px-1 !shadow-custom sm:px-6">
			<CardHeader className="px-8">
				<CardTitle className="pt-8 text-2xl font-semibold xs:text-3xl">
					Welcome back! <br />
					Let&apos;s <span className="text-accent-foreground ">get started</span>
				</CardTitle>
			</CardHeader>
			<CardContent className="px-8 py-0">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="flex flex-col justify-center pt-6"
					>
						<TextInput name="email" label="Email" placeholder="Email" />
						<PasswordInput name="password" label="Password" placeholder="Password" />
						<div className="flex items-center justify-end py-1">
							<Link
								href="/login/password-reset"
								className="flex items-center justify-center text-xs font-normal text-[#898787]"
							>
								Forgotten password?
							</Link>
						</div>

						<Button className="mx-4 mt-6 h-12 shadow-custom sm:mx-6" type="submit">
							Login
						</Button>
					</form>
				</Form>

				<div className="mt-4 flex w-full items-center">
					<div className="grow border-t border-secondary/20" />
					<span className="mx-4 shrink text-muted-foreground">or</span>
					<div className="grow border-t border-secondary/20" />
				</div>

				<div className="flex flex-col gap-y-4 py-4">
					<AuthProviderButton
						title="Google"
						logo={googleIcon}
						onClick={handleGoogleAuth}
					/>
					<AuthProviderButton title="Apple" logo={appleIcon} onClick={handleAppleAuth} />
				</div>
			</CardContent>

			<CardFooter className="flex justify-center gap-1 py-8 text-center text-xs text-muted-foreground">
				<span>Don&apos;t have an account? </span>
				<Link className="text-accent-foreground underline" href="/signup">
					Sign up
				</Link>
			</CardFooter>
		</Card>
	);
}
