import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Eye, EyeOff } from "lucide-react";
import Image from "next/image";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

type PasswordInputProps = {
	name: string;
	label: string;
	placeholder?: string;
	value?: string;
	disabled?: boolean;
	intent?: "column" | "inlined";
};

function PasswordInput({ name, label, placeholder, value, disabled, intent }: PasswordInputProps) {
	const { control } = useFormContext();
	const [visible, setVisible] = useState<boolean>(false);
	return (
		<FormField
			defaultValue={value}
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem intent={intent} className="relative">
					{label.length > 0 && <FormLabel intent={intent}>{label}</FormLabel>}
					<FormControl>
						<div className="relative flex items-center justify-end">
							<Input
								className="h-11 border-[#07A8BC33]"
								disabled={disabled}
								placeholder={placeholder}
								type={visible ? "text" : "password"}
								{...field}
							/>
							<span
								onClick={() => setVisible(!visible)}
								className="absolute flex cursor-pointer p-3"
							>
								{visible ? (
									<>
										<Eye stroke="#07A8BC" size={16} strokeWidth="2" />
									</>
								) : (
									<>
										<EyeOff stroke="#07A8BC" size={16} strokeWidth="2" />
									</>
								)}
							</span>
						</div>
					</FormControl>
					<FormMessage intent={intent} />
				</FormItem>
			)}
		/>
	);
}

export default PasswordInput;
